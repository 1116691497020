
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import BGImage11 from '../assets/fe/images/menubg.png';

import { RoutesPath } from '../Routes';
import { API } from '../Helpers/ApiService';
import { URL } from '../Helpers/URL';

const Contact = () => {
    const { t, i18n } = useTranslation();
    const auth = useSelector(state => state.auth);
    const [searchParams] = useSearchParams();

    const [form, setForm] = useState({});
    const [formLoading, setFormLoading] = useState(false);
    const [addons, setAddons] = useState([]);

    useEffect(() => {
        getData();
    }, [auth.language]);

    useEffect(() => {
        if (auth.value) {
            setForm(data => {
                data['name'] = auth.value?.firstName;
                data['email'] = auth.value?.email;
                data['phone'] = auth.value?.mobile;
                return data;
            });
        }
    }, [auth]);

    const getData = () => {
        API({
            url: URL.REGISTER_DATA,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            setAddons([]);
            let addOns = [];
            res?.addons?.map(e => {
                addOns.push({ id: e.id, addon: e.addon[auth.language] ? e.addon[auth.language] : e.addon['en'] });
            })
            setAddons(addOns);
        });
        setFormData('support', [{
            addon: '',
            otherDetails: ''
        }]);
    }

    const setFormData = (name, value) => {
        var data = _.cloneDeep(form);
        data[name] = value;
        setForm(data);
    }

    const setSupportData = (name, index, value) => {
        let data = _.cloneDeep(form);
        if (name == 'addon') {
            if (data['support'].find(e => e[name] == value)) {
                toast.error('Already selected!');
                return;
            }
        }
        data['support'][index][name] = value;
        setForm(data);
    }

    const addSupportData = () => {
        let data = _.cloneDeep(form);
        data['support'].push({
            addon: '',
            otherDetails: ''
        });
        setForm(data);
    }

    const removeSupportData = (index) => {
        let data = _.cloneDeep(form);
        data['support'].splice(index, 1);
        setForm(data);
    }

    const handleSubmitSupport = () => {
        setFormLoading(true);
        API({
            url: URL.SUPPORT,
            method: 'POST',
            data: form
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            toast.success('Message sent successfully!');
            setForm({
                support: [{
                    addon: '',
                    otherDetails: ''
                }]
            });
        });
    }

    return (
        <>
            <section className="page-title" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{t('support')}</h1>
                        {/* <ul className="page-breadcrumb">
                            <li><Link to={RoutesPath.Home.path}>{t('home')}</Link></li>
                            <li>{t('support')}</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            {/* <section className="contact-section pt-5 pb-0">
                <div className="auto-container">
                    <div className="sec-title text-center mb-5">
                        <h2>{t('get_in_touch')}</h2>
                        <span className="divider mt-2"></span>
                    </div>

                    <div className="contact-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div className="input-outer">
                                        <input type="text" name="name" placeholder={t('name')} onChange={e => setFormData('name', e.target.value)} value={form?.name ?? ''} />
                                        <span className="icon fa fa-user"></span>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div className="input-outer">
                                        <input type="email" name="email" placeholder={t('email')} required="" onChange={e => setFormData('email', e.target.value)} value={form?.email ?? ''} />
                                        <span className="icon fa fa-envelope"></span>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div className="input-outer">
                                        <input type="text" name="phone" placeholder={t('phone')} required="" onChange={e => setFormData('phone', e.target.value)} value={form?.phone ?? ''} />
                                        <span className="icon fa fa-phone"></span>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div className="input-outer">
                                        <input type="text" name="subject" placeholder={t('subject')} required="" onChange={e => setFormData('subject', e.target.value)} value={form?.subject ?? ''} />
                                        <span className="icon fa fa-pencil-alt"></span>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                    <textarea name="message" style={{ height: 80 }} placeholder={t('message')} onChange={e => setFormData('message', e.target.value)} value={form.message ?? ''}></textarea>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12 form-group text-right">
                                    <button className="theme-btn btn-style-one" onClick={handleSend} type="button" disabled={formLoading} name="submit-form"><span className="btn-title">{t('submit_now')}</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section> */}
            <section className="about-section-two pt-1 pb-3">
                <div className="auto-container">
                    <div className="sec-title text-center mb-2 mt-3">
                        <h2>{t('get_in_touch')}</h2>
                        <span className="divider mt-2"></span>
                    </div>
                    <div className='row'>
                        <div className='col-12' style={{ overflowY: 'auto' }}>
                            <table className="table table-bordered table-responsive text-center contact-table" style={{ display: 'table', }}>
                                <thead>
                                    <tr className='app-bg-primary'>
                                        <th>{t("TYPE_OF_ASSISTANCE_REQUIRED")}</th>
                                        <th>{t("OFFICER_IN_CHARGE")}</th>
                                        <th>{t("CONTACT_NUMBER")}</th>
                                        <th>{t("DEPUTY_OFFICER")}</th>
                                        <th>{t("CONTACT_NUMBER")}</th>
                                    </tr>
                                </thead>
                                <tbody id="rows">
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("HOTEL_ACCOMMODATION")}</b></td>
                                        <td>
                                            PRADEEP KUMAR GUPTA <br />
                                            IG-RPF<br />
                                            West Central Railway
                                        </td>
                                        <td>+91 97524 15700</td>
                                        <td>
                                            ANURAG MEENA<br />
                                            Senior Divisional Security <br />Commissioner - RPF, Jodhpur<br />

                                        </td>
                                        <td>+91 90011 98700</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("REGISTRATION")}</b></td>
                                        <td rowSpan='2'>
                                            MUNAWAR KHURSHEED<br />
                                            IG-RPF<br />
                                            Academy, Lucknow
                                        </td>
                                        <td rowSpan='2'>+91 94375 68700</td>
                                        <td rowSpan='2'>
                                            ACHYUTA NAND JHA<br />
                                            DIG-RPF<br />
                                            Academy, Lucknow
                                        </td>
                                        <td rowSpan='2'>+91 97948 33731</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("CONFERENCE_HALL")}</b></td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("TRANSPORTATION")}</b></td>
                                        <td rowSpan='3'>
                                            S. MAYANK<br />
                                            IG(Construction)-RPF<br />
                                            East Coast Railway<br />
                                        </td>
                                        <td rowSpan='3'>+91 96924 17496</td>
                                        <td>
                                            RAJ KUMAR<br />
                                            Inspector - CIB/RPF, Ajmer<br />
                                            {/* Railway Board */}
                                        </td>
                                        <td>+91 80786 05526</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("TOURISM_&_HERITAGE")}</b></td>
                                        <td>
                                            AMBUJ TRIPATHI<br />
                                            Inspector-Cons<br />
                                            East Coast Railway
                                        </td>
                                        <td>+91 97948 35732</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("LIAISON_OFFICER")}</b></td>
                                        <td>
                                            KUMAR NISHANT<br />
                                            Deputy Chief Security <br />
                                            Commissioner -RPF<br />
                                            South Western Railway
                                        </td>
                                        <td>+91 96863 77500</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("VISA_&_IMMIGRATION")}</b></td>
                                        <td>
                                            M.S. SUNIL<br />
                                            DIG(Coordination)-RPF<br />
                                            Railway Board
                                        </td>
                                        <td>+91 97176 47669</td>
                                        <td>
                                            RAJ KUMAR<br />
                                            Inspector-RPF<br />
                                            Railway Board
                                        </td>
                                        <td>+91 82875 12567</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary' rowSpan='4'><b>{t("AIRPORT_RECEPTION")}</b></td>
                                        <td>
                                            SANDEEP RAVIVANSHI<br />
                                            DIG-RPF<br />
                                            North Western Railway
                                        </td>
                                        <td>+91 90011 95701</td>
                                        <td>
                                            -
                                        </td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            ASHOK TYAGI<br />
                                            Assistant Security <br />
                                            Commissioner -RPF<br />
                                            North western Railway<br />
                                            [JAIPUR AIRPORT]
                                        </td>
                                        <td>+91 90011 99701</td>
                                        <td>
                                            GAJRAJ JAGATPURA<br />
                                            Sub-Inspector-RPF<br />
                                            North Western Railway
                                        </td>
                                        <td>+91 90011 99729</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            VIKRAM SINGH<br />
                                            Inspector-RPF<br />
                                            Western Railway<br />
                                            [MUMBAI AIRPORT]
                                        </td>
                                        <td>+91 90044 99722</td>
                                        <td>
                                            KAMLESH PATIDHAR<br />
                                            Sub-Inspector-RPF<br />
                                            Western Railway
                                        </td>
                                        <td>+91 78792 99474</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            NEERAJ KUMAR<br />
                                            Inspector-RPF<br />
                                            Northern Railway<br />
                                            [NEW DELHI AIRPORT]
                                        </td>
                                        <td>+91 97176 48730</td>
                                        <td>
                                            -
                                        </td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("FOOD_&_CATERING")}</b></td>
                                        <td>
                                            SANDEEP RAVIVANSHI<br />
                                            DIG-RPF<br />
                                            North Western Railway<br />
                                        </td>
                                        <td>+91 90011 95701</td>
                                        <td>
                                            SHAKTI SINGH SHIKHAWAT<br />
                                            Assistant Security <br />
                                            Commissioner-RPF<br />
                                            North Western Railway
                                        </td>
                                        <td>+91 90011 95703</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("GALA_DINNER")}</b></td>
                                        <td>
                                            RAVINDRA VERMA<br />
                                            IG-RPF<br />
                                            North Central Railway
                                        </td>
                                        <td>+91 97948 35700</td>
                                        <td>
                                            ANUBHAV JAIN<br />
                                            Senior Divisional Security <br />
                                            Commissioner-RPF<br />
                                            Agra
                                        </td>
                                        <td>+91 97605 36700</td>
                                    </tr>
                                    <tr >
                                        <td className='app-bg-primary'><b>{t("SECURITY_&_MEDICAL")}</b></td>
                                        <td>
                                            R. P. PAWAR<br />
                                            IG-RPF<br />
                                            Kharagpur Training Centre
                                        </td>
                                        <td>+91 82087 05299</td>
                                        <td>
                                            JYOTI MANI<br />
                                            Senior Divisional Security <br />
                                            Commissioner-RPF<br />
                                            Jaipur
                                        </td>
                                        <td>+91 90011 99700</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("UIC_CENTRAL_CONTROL")}</b></td>
                                        <td>
                                            KAMALJOT BRAR<br />
                                            Senior Security <br />
                                            Commissioner-RPF<br />
                                            RCP Kapurthala
                                        </td>
                                        <td>+91 97792 41755</td>
                                        <td>
                                            Mr Ajay Sharma<br />
                                            Assistant Commandant - RPSF
                                        </td>
                                        <td>+91 85959 28057</td>
                                    </tr>
                                    <tr>
                                        <td className='app-bg-primary'><b>{t("TECHNICAL_SUPPORT_AT")}<br /> {t("_CONFERENCE_HALL")}</b></td>
                                        <td>
                                            B. V. RAO<br />
                                            IG(Tech)-RPF<br />
                                            Railway Board
                                        </td>
                                        <td>+91 97176 30702</td>
                                        <td>
                                            CH. RAGHUVEER<br />
                                            Senior Divisional Security <br />
                                            Commissioner-RPF<br />
                                            Waltair
                                        </td>
                                        <td>+91 89780 80700</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <div className="contact-form">
                        <form>
                            <div className="row clearfix">
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div className="input-outer">
                                        <input type="text" name="name" placeholder={t('name')} onChange={e => setFormData('name', e.target.value)} value={form?.name ?? ''} />
                                        <span className="icon fa fa-user"></span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div className="input-outer">
                                        <input type="email" name="email" placeholder={t('email')} required="" onChange={e => setFormData('email', e.target.value)} value={form?.email ?? ''} />
                                        <span className="icon fa fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div className="input-outer">
                                        <input type="text" name="phone" placeholder={t('phone')} required="" onChange={e => setFormData('phone', e.target.value)} value={form?.phone ?? ''} />
                                        <span className="icon fa fa-phone"></span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <div className="input-outer">
                                        <input type="text" name="message" placeholder={t('message')} required="" onChange={e => setFormData('message', e.target.value)} value={form?.message ?? ''} />
                                        <span className="icon fa fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-lg-12 col-md-12 col-sm-12 form-group text-right mt-5">
                                    <button className="theme-btn btn-style-one" onClick={handleSubmitSupport} type="button" disabled={formLoading} name="submit-form"><span className="btn-title">{t('submit_now')}</span></button>
                                </div>
                            </div>
                        </form>
                    </div> */}
                </div>
            </section>
            <section className="contact-section pt-3 pb-0">
                <div className="auto-container">
                    <div className="sec-title text-center mb-2">
                        <h2>{t('locate_us')}</h2>
                        <span className="divider mt-2"></span>
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.9430230622875!2d75.794000214993!3d26.841764469602676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5e591a66633%3A0xef403de8e5c9ad89!2sJaipur%20Marriott%20Hotel!5e0!3m2!1sen!2sin!4v1669873087734!5m2!1sen!2sin" height="400" style={{ border: 0, width: '100%' }} loading="lazy" ></iframe>

                </div>
            </section>
        </>
    );
}
export default Contact;
