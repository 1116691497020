import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { BASE_URL, DOMAIN_URL, modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Multiselect from 'multiselect-react-dropdown';


const Users = () => {
    const fd = {
        firstName: "",
        surname: "",
        designation: "",
        organization: "",
        email: "",
        mobile: "",
        country: "",
        languageSpoken: [],
        participationType: "",
        idCard: null,
    }

    const columns = [
        {
            name: 'Name',
            center: true,
            selector: row => row.firstName + ' ' + row.surname,
        },
        {
            name: 'Designation',
            center: true,
            selector: row => row?.designation ?? '',
        },
        {
            name: 'Organization',
            center: true,
            selector: row => row.organization,
        },
        {
            name: 'Email',
            center: true,
            selector: row => row.email,
        },
        {
            name: 'Whatsapp',
            center: true,
            selector: row => row.mobile,
        },
        {
            name: 'Country',
            center: true,
            selector: row => row.country.country.en,
        },
        {
            name: 'Participation Type',
            center: true,
            selector: row => row.participationType,
        },
        {
            name: 'Languages',
            center: true,
            selector: row => row.languages.map((e, i) => { return (i != 0 ? ',' + e?.language?.language : e?.language?.language) }),
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];
    const [createData, setCreateData] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [certModel, setCertModel] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [createModel, setCreateModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.USERS + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });
        API({
            url: URL.REGISTER_DATA,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            setCreateData(res);
        });
    }

    const handleCreate = () => {
        setCreateModel({
            mode: 'create',
        });
    }

    const handleEdit = (data) => {
        API({
            url: URL.USERS + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            var tempFd = _.cloneDeep(fd);
            Object.assign(tempFd, res);

            tempFd.idCard = null;
            delete tempFd.role;
            delete tempFd.id;
            delete tempFd.isEmailVerified;
            if (tempFd?.otherRequirements) {
                delete tempFd.otherRequirements;
            }
            if (tempFd?.department) {
                delete tempFd.department;
            }
            if (tempFd?.position) {
                delete tempFd.position;
            }
            if (tempFd?.city) {
                delete tempFd.city;
            }

            setForm(tempFd);
            setCreateModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        var payload = _.cloneDeep(form);
        if (payload?.languageSpoken) {
            payload.languageSpoken = payload?.languageSpoken.map(e => { return e.id });
        }

        setFormLoading(true);
        API({
            url: createModel?.mode == 'edit' ? URL.USERS + '/' + createModel?.editId : URL.USERS,
            method: createModel?.mode == 'edit' ? 'PATCH' : 'POST',
            data: payload,
            formData: true
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });

    }

    const resetForm = () => {
        setCreateModel(null);
        setForm(fd);
        getData();
    }


    const setFormData = (value, name) => {
        let data = _.cloneDeep(form);
        data[name] = value;
        setForm(data);
    }

    const ActionColumn = ({ row }) => {
        const handleView = () => {
            let index = data.findIndex(e => e.id == row.id);
            if (index != -1) {
                setModel({
                    index: index,
                });
            }
        }
        const handleAccept = () => {
            API({
                url: URL.USERS + '/' + row.id,
                method: 'PATCH',
                data: { isAccepted: true }
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                toast.success('User approved successfully!')
                getData();
            });
        }
        return (
            <>
                <button className='ml-1 btn btn-info btn-sm' title='certificate upload' onClick={() => setCertModel(row)}>< i className='fa fa-upload' /></button>
                <button className='ml-1 btn btn-info btn-sm' onClick={handleView}>< i className='fa fa-eye' /></button>
                <button className='btn btn-info btn-sm ml-1' onClick={() => handleEdit(row)}>< i className='fa fa-edit' /></button>
                {
                    !row?.isAccepted ? <button className='ml-1 btn btn-success btn-sm' onClick={handleAccept}>< i className='fa fa-check' /></button> : null
                }

            </>
        );
    }

    const exportUser = () => {
        API({
            url: URL.EXPORT_USER,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}excel/${res.file}`, "_blank");
        });
    }

    const uploadCert = (e) => {
        let files = e.target.files;
        if (!files.length) {
            return;
        }
        let payload = { file: files[0] };
        API({
            url: URL.UPLOAD,
            method: 'POST',
            data: payload,
            formData: true
        }).then(res => {
            e.target.value = null;
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            API({
                url: URL.USER_CERT_UPDATE,
                method: 'POST',
                data: {
                    id: certModel.id,
                    cert: 'uploads/' + res[0].filename
                },
                formData: true
            }).then(res1 => {
                if (res1?.code) {
                    toast.error(res.message);
                    return;
                }
                getData();
                setCertModel({ fileUrl: DOMAIN_URL + 'uploads/' + res[0].filename });
            });
        });
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Users</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Users</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Users Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <button className='btn btn-secondary' onClick={exportUser}>
                                                Export
                                            </button>
                                            <button className='btn btn-primary ml-2' onClick={handleCreate}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => setModel(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>User Details</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => setModel(null)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <table border={0} width={500}>
                                <tr>
                                    <td>Name:</td>
                                    <td>{data[model?.index]?.firstName + ' ' + data[model?.index]?.surname}</td>
                                </tr>
                                <tr>
                                    <td>Organization:</td>
                                    <td>{data[model?.index]?.organization}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td>{data[model?.index]?.email}</td>
                                </tr>
                                <tr>
                                    <td>WhatsApp:</td>
                                    <td>{data[model?.index]?.mobile}</td>
                                </tr>
                                <tr>
                                    <td>City:</td>
                                    <td>{data[model?.index]?.city}</td>
                                </tr>
                                <tr>
                                    <td>Country:</td>
                                    <td>{data[model?.index]?.country?.country?.en ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td>Type of Participation:</td>
                                    <td>{data[model?.index]?.participationType ?? '-'}</td>
                                </tr>
                                {
                                    data[model?.index]?.languages.length ? (
                                        <tr>
                                            <td>Language Spoken:</td>
                                            <td>
                                                {
                                                    data[model?.index]?.languages.map((e, i) => <span>{i != 0 ? ',' + e?.language?.language : e?.language?.language}</span>)
                                                }
                                            </td>
                                        </tr>
                                    ) : null
                                }
                                {
                                    data[model?.index]?.idCard ? (
                                        <tr>
                                            <td>Id Card:</td>
                                            <td>
                                                <img src={DOMAIN_URL + data[model?.index]?.idCard} width="200" />
                                            </td>
                                        </tr>
                                    ) : null
                                }
                            </table>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={certModel ? true : false}
                // onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-12'>
                            {
                                certModel?.fileUrl ? <img src={certModel?.fileUrl} /> : certModel?.certificate ? <img src={DOMAIN_URL + certModel?.certificate} /> : <div className="form-group mt-3 mb-3 ">
                                    <input type="file" className="form-control" accept='image/*' onChange={uploadCert} />
                                </div>
                            }
                        </div>
                        <div className='col-12 text-right mt-4'>
                            <button className='btn btn-primary' onClick={() => setCertModel(null)}>Close</button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={createModel ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[modelBoxStyle, { width: '60%' }]}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{createModel?.mode == 'create' ? 'Create' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form.firstName} placeholder="Firstname" onChange={e => setFormData(e.target.value, 'firstName')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form.surname} placeholder="Surname" onChange={e => setFormData(e.target.value, 'surname')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form.designation} placeholder="Designation" onChange={e => setFormData(e.target.value, 'designation')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form.organization} placeholder="Organization" onChange={e => setFormData(e.target.value, 'organization')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="email" className="form-control" value={form.email} placeholder="Email" onChange={e => setFormData(e.target.value, 'email')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form.mobile} placeholder="Whatsapp number" onChange={e => setFormData(e.target.value, 'mobile')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <select onChange={(e) => setFormData(e.target.value, 'country')} defaultValue={form.country} className="form-control">
                                    <option value="" disabled hidden>Select country</option>
                                    {
                                        createData?.country?.map((element, index) => <option key={index} value={element.id}>{element.country['en']}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <Multiselect
                                    // className="form-control"
                                    placeholder='Select language'
                                    options={createData?.languages ?? []}
                                    onSelect={(list) => setFormData(list, 'languageSpoken')}
                                    onRemove={(list) => setFormData(list, 'languageSpoken')}
                                    displayValue="language"
                                />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <select onChange={(e) => setFormData(e.target.value, 'participationType')} defaultValue={form.participationType} className="form-control" >
                                    <option value="" disabled hidden>Select participation type</option>
                                    <option value="Virtual">Virtual</option>
                                    <option value="Physical">Physical</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group  ">
                                <label style={{ fontSize: 12 }} className="register-label text-left m-0">Official ID or Government ID (Indian Delegates Only)</label><br />
                                <input accept="image/*" type="file" onChange={(e) => setFormData(e.target.files[0], 'idCard')} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default Users;
