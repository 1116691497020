
export const RoutesPath = {
    Home: { path: "/" },
    Login: { path: "/login" },
    Register: { path: "/register" },
    Account: { path: "/account" },
    AboutInner: { path: "/about/:id" },
    AgendaInner: { path: "/agenda/:id" },
    SupportInner: { path: "/support-service/:id" },
    CulturalProgram: { path: "/cultural-program/:id" },
    TourismHeritage: { path: "/tourism-heritage/:id" },
    Speakers: { path: "/speakers" },
    Contact: { path: "/contact" },
    TermAndCondition: { path: "/terms-condition" },
    PrivacyPolicy: { path: "/privacy-policy" },
    Schedule: { path: "/schedule" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password/:token" },
    FeedbackFormFront: { path: "/feedback/:id" },

    Admin: { path: "/backoffice" },
    Dashboard: { path: "/backoffice/dashboard" },
    Country: { path: "/backoffice/countries" },
    Domain: { path: "/backoffice/domains" },
    Agency: { path: "/backoffice/agencies" },
    Designation: { path: "/backoffice/designations" },
    Department: { path: "/backoffice/departments" },
    CMSPages: { path: "/backoffice/cms-pages" },
    Speaker: { path: "/backoffice/speaker" },
    Addon: { path: "/backoffice/addon" },
    Language: { path: "/backoffice/language" },
    Users: { path: "/backoffice/users" },
    Supports: { path: "/backoffice/support" },
    Notification: { path: "/backoffice/notification" },
    Setting: { path: "/backoffice/setting" },
    Glimpses: { path: "/backoffice/glimpses" },
    Download: { path: "/backoffice/download" },
    FeedbackFrom: { path: "/backoffice/FeedbackFrom" },
    FeedbackReport: { path: "/backoffice/feedback-report" },
    FeedbackReportDetails: { path: "/backoffice/feedback-report-details/:formId" },
    EventSummaryReport: { path: "/backoffice/event-summary-report" },

    NotFound: { path: "/404" },
};