import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";

import BackGround11 from '../assets/fe/images/background/11.jpg';

import { API } from "../Helpers/ApiService";
import { URL } from "../Helpers/URL";
import { RoutesPath } from "../Routes";

const RegisterNew = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);

    const [data, setData] = useState(null);
    const [addons, setAddons] = useState([]);
    const [form, setForm] = useState({});
    const [consent, setConsent] = useState(false);
    const [acknowledge, setAcknowledge] = useState(false);
    const [captcha, setCaptcha] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setAddOnsList();
    }, [auth.language, data]);

    const onChange = (value) => {
        setCaptcha(value)
    }

    const getData = () => {
        API({
            url: URL.REGISTER_DATA,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            setData(res);
        });
    }

    const setAddOnsList = () => {
        setAddons([]);
        let addOns = [];
        data?.addons?.map(e => {
            addOns.push({ id: e.id, addon: e.addon[auth.language] });
        })
        addOns.push({ id: 0, addon: 'Other' });
        setAddons(addOns);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        var payload = _.cloneDeep(form);
        if (payload?.requirements) {
            payload.requirements = payload?.requirements.filter(e => e != 0);
        }
        if (payload?.languageSpoken) {
            payload.languageSpoken = payload?.languageSpoken.map(e => { return e.id });
        }
        if (!consent) {
            toast.error('Please check consent!');
            return;
        }
        if (!acknowledge) {
            toast.error('Please check acknowledge!');
            return;
        }

        if (consent && acknowledge) {
            payload['captcha'] = captcha;
            API({
                url: URL.REGISTER,
                method: 'POST',
                data: payload,
                formData: true
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message);
                    return;
                }
                toast.success('Registered successfully!');
                navigate(RoutesPath.Login.path);
            });
        }
    }

    const setFormData = (name, value) => {
        let formData = _.cloneDeep(form);
        if (name === 'requirements') {
            formData[name] = value.map(e => { return e.id });
        } else {
            formData[name] = value;
        }
        setForm(formData);
    }

    return (
        <>
            <center>
                <section className="register-section">
                    <div className="row">
                        <div className="col-md-7 col-sm-12">
                            <div className="row mt-2 justify-content-center">
                                <div className="col-6 justify-content-center d-flex">
                                    <img src={require('../assets/fe/images/G20-logo.png')} width={150} />
                                </div>
                                <div className="col-6 justify-content-center d-flex">
                                    <img src={require('../assets/fe/images/UIC-logo-2.png')} width={150} />
                                </div>
                                <h2 className="mt-2">{t('register_here')}</h2>
                                <div className="styled-form register-form mt-2 reg-form" style={{ width: '95%' }}>
                                    <form onSubmit={onSubmit}>
                                        <div className="row">
                                            <div className="col-12 text-left mb-3">
                                                <small style={{ color: '#f00', textAlign: 'left' }}>* marked fields are mandatory</small>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('first_name')}
                                                        <span className="red">*</span>
                                                    </label>
                                                    <input type="text" onChange={(e) => setFormData('firstName', e.target.value)} className="register-input" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('surname')}
                                                        <span className="red">*</span>
                                                    </label>
                                                    <input type="text" onChange={(e) => setFormData('surname', e.target.value)} className="register-input" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('designation')}
                                                        <span className="red">*</span>
                                                    </label>
                                                    <input type="text" onChange={(e) => setFormData('designation', e.target.value)} className="register-input" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('organization')}
                                                        <span className="red">*</span>
                                                    </label>
                                                    <input type="text" onChange={(e) => setFormData('organization', e.target.value)} className="register-input" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('official_mail')}
                                                        <span className="red">*</span>
                                                    </label>
                                                    <input type="email" onChange={(e) => setFormData('email', e.target.value)} className="register-input" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('whatsapp_number')}
                                                    </label>
                                                    <input type="text" onChange={(e) => setFormData('mobile', e.target.value)} className="register-input" />
                                                </div>
                                            </div>


                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('country')}
                                                        <span className="red">*</span>
                                                    </label>
                                                    <select onChange={(e) => setFormData('country', e.target.value)} defaultValue={''} className="register-input">
                                                        <option value="" disabled hidden>{t('select_country')}</option>
                                                        {
                                                            data?.country?.map((element, index) => <option key={index} value={element.id}>{element.country[auth.language] ? element.country[auth.language] : element.country['en']}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('language_spoken')}
                                                    </label><br />
                                                    <Multiselect
                                                        className="register-input"
                                                        options={data?.languages ?? []}
                                                        onSelect={(list) => setFormData('languageSpoken', list)}
                                                        onRemove={(list) => setFormData('languageSpoken', list)}
                                                        displayValue="language"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">
                                                        {t('participantion_type')}
                                                        <span className="red">*</span>
                                                    </label>
                                                    <select onChange={(e) => setFormData('participationType', e.target.value)} defaultValue={''} className="register-input" >
                                                        <option value="" disabled hidden>{t('select_type')}</option>
                                                        <option value="Virtual">{t('virtual')}</option>
                                                        <option value="Physical">{t('physical')}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label text-left">{t('id_card')}</label><br />
                                                    <input accept="image/*" type="file" className="w-100 p-2 register-input" onChange={(e) => setFormData('idCard', e.target.files[0])} />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <span className="adon-icon"></span>
                                                    <label className="register-label">{t('special_remarks')}</label>
                                                    <textarea rows="5" cols="50" name="description" className="register-input" onChange={(e) => setFormData('specialRemarks', e.target.value)}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex align-items-center text-left">
                                                <input type="checkbox" id="remember-me" className="mr-2" onChange={() => setConsent(!consent)} />
                                                <label className="remember-me mb-0" style={{ fontSize: 15 }} htmlFor="remember-me"> {t('tnc')}</label>
                                            </div>
                                            <div className="col-12 d-flex align-items-center text-left mt-3">
                                                <input type="checkbox" id="remember-me" className="mr-2" onChange={() => setAcknowledge(!acknowledge)} />
                                                <label className="remember-me mb-0" style={{ fontSize: 15, lineHeight: 1.3 }} htmlFor="remember-me"> {t('acknowledge')}</label>
                                            </div>
                                            <div className="col-12">
                                                <ReCAPTCHA
                                                    sitekey="6LcGc0EkAAAAAHPEO9k3ZicHHVL5nM1QN65cxvmI"
                                                    onChange={onChange}
                                                />
                                                <button type="submit" className="theme-btn btn-style-one mt-4"><span className="btn-title">{t('register')}</span></button>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group submit-text">
                                                    {/* <span style={{ color: 'red', fontWeight: 'bolder' }}>{t('bottom_note')}</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <img src={require('../assets/fe/images/register.png')} style={{ width: '95%' }} />
                        </div>
                    </div>
                </section>
            </center>
        </>
    );
}
export default RegisterNew;
