import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Modal } from '@mui/material';

import BGImage11 from '../assets/fe/images/menubg.png';

import { API } from "../Helpers/ApiService";
import { DOMAIN_URL, modelBoxStyle } from "../Helpers/Constants";
import { URL } from "../Helpers/URL";
import { RoutesPath } from "../Routes";
import moment from "moment";

const Speakers = () => {
    const { t, i18n } = useTranslation();
    const auth = useSelector(state => state.auth);

    const [speakers, setSpeakers] = useState([]);
    const [speakerModel, setSpeakerModel] = useState(null);

    useEffect(() => {
        API({
            url: URL.GET_SPEAKERS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setSpeakers(res)
        });
    }, []);
    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + BGImage11 + ")" }} >
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{t('our_speaker')}</h1>
                        {/* <ul className="page-breadcrumb">
                            <li><Link to={RoutesPath.Home.path}>{t('home')}</Link></li>
                            <li>{t('our_speaker')}</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            {
                speakers.length ? <section className="speakers-section">
                    <div className="anim-icons full-width">
                        <span className="icon icon-dotted-circle"></span>
                    </div>
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            {/* <span className="sub-title">{t('our_speaker')}</span> */}
                            <h2>{t('speaker_expert')}
                                {/* <br />{t('in_field')} */}
                            </h2>
                            <span className="divider"></span>
                        </div>
                        <div className="row">
                            {
                                speakers.map((element, index) => <div key={index} onClick={() => setSpeakerModel(element)} className="speaker-block col-lg-3 col-md-6 col-sm-12 wow fadeInUp">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image">
                                                <a>
                                                    <img src={DOMAIN_URL + element.image} alt="" />
                                                </a>
                                            </figure>
                                        </div>
                                        <div className="info-box text-center">
                                            <h4 className="name"><a>{element.name}</a></h4>
                                            <span className="designation">{element.designation[auth.language]}</span>
                                        </div>
                                    </div>
                                </div>)
                            }


                        </div>
                    </div>
                </section> : null
            }
            <Modal
                open={speakerModel ? true : false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[modelBoxStyle, { width: '70%', overflow: 'auto', maxHeight: '100vh' }]} className="speaker-popup-box">
                    <div className='row'>
                        <div className='col-6'>
                            <h5>Details</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => setSpeakerModel(null)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mt-2 mb-3'>
                            <div style={{
                                float: 'left',
                                maxWidth: '30%',
                                textAlign: "center",
                                margin: ' 0px 12px 3px 0px',
                                border: '1px solid #f2f2f2',
                                padding: 20,
                            }}
                                className="speaker-popup">
                                <img src={DOMAIN_URL + speakerModel?.image} alt="" />
                                <div className='text-center'>
                                    <h5 className="name"><a>{speakerModel?.name}</a></h5>
                                    <span className="designation">{speakerModel?.designation[auth.language]}</span>
                                </div>
                            </div>
                            <p className="text-justify mb-3">
                                <b>Brief about Presenter: </b>
                                {speakerModel?.description ? speakerModel?.description[auth.language] : ''}
                            </p>
                            {
                                speakerModel?.session ? <p className="designation text-justify">
                                    <b>Session: </b>
                                    <span>{speakerModel?.session[auth.language]}</span>
                                </p> : null
                            }
                            {
                                speakerModel?.dateTime ? <p className="designation mt-3 text-justify">
                                    <b>Time: </b>
                                    <span>{moment(speakerModel?.dateTime).format('DD-MM-YYYY hh:mm A')}</span>
                                </p> : null
                            }
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
export default Speakers;
