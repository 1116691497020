export const URL = {
    adminLogin: 'admin/login',
    LOGIN: 'auth/login',
    REGISTER: 'auth/register',
    REGISTER_DATA: 'auth/register/data',
    ME: 'auth/me',
    GET_PAGES: 'data/pages',
    GET_PAGE: 'data/page/',
    GET_SPEAKERS: 'data/speakers',
    SUPPORT: 'data/support',
    USER_DOWNLOADS: 'data/downloads',
    OPINION_CLICK: 'data/opinion/click/',
    FEEDBACK_FORM_GET: 'data/feedback/form/',
    FEEDBACK_SEND: 'data/feedback',
    CONTACT: 'contact',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password/',

    //Admin URLS
    UPLOAD: 'auth/upload',
    COUNTRY: 'country',
    DOMAIN: 'domain',
    AGENCY: 'agency',
    DESIGNATION: 'designation',
    DEPARTMENT: 'department',
    CMS_PAGES: 'cmsPages',
    SPEAKER: 'speaker',
    ADDONS: 'addon',
    USERS: 'users',
    DOWNLOADS: 'download',
    USERS_ALL: 'data/alluser',
    FEEDBACK_REPORT: 'data/feedback-report',
    FEEDBACK_REPORT_DETAILS: 'data/feedback-report-details/',
    EVENT_SUMMARY_REPORT: 'data/event-summary-report',
    EXPORT_USER: 'data/users/export',
    DOWNLOAD_FILE: 'data/download/',
    LANGUAGE: 'language',
    NOTIFICATION_SEND: 'notification/send',
    GET_SETTINGS: 'data/settings',
    UPDATE_SETTINGS: 'data/settings/update',
    USER_CERT_UPDATE: 'data/users/cer/update',
    USER_CERT_DELETE: 'data/users/cer/remove',
    SESSION_UPDATE: 'data/session/',
    FEEDBACK_FORM: 'feedbackform',
};