import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { DOMAIN_URL, modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';


const Speaker = () => {
    const fd = {
        image: '',
        name: '',
        dateTime: '',
        session: {
            es: "",
            fr: "",
            de: "",
            it: "",
            en: "",
            hi: "",
        },
        designation: {
            es: "",
            fr: "",
            de: "",
            it: "",
            en: "",
            hi: "",
        },
        description: {
            es: "",
            fr: "",
            de: "",
            it: "",
            en: "",
            hi: "",
        }
    }
    const columns = [
        {
            name: 'Name',
            center: true,
            selector: row => row.name,
        },
        {
            name: 'Sequence',
            center: true,
            cell: row => <SequenceColumn row={row} />
        },
        {
            name: 'Status',
            center: true,
            selector: row => row.isActive,
            cell: row => <StatusColumn row={row} />
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        getData();
    }, []);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.SPEAKER + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });
    }

    const handleCreate = () => {
        setModel({
            mode: 'create',
        });
    }

    const handleEdit = (data) => {
        API({
            url: URL.SPEAKER + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            if (!res?.description) {
                res.description = {
                    es: "",
                    fr: "",
                    de: "",
                    it: "",
                    en: "",
                    hi: "",
                };
            }
            if (!res?.session) {
                res.session = {
                    es: "",
                    fr: "",
                    de: "",
                    it: "",
                    en: "",
                    hi: "",
                };
            }
            delete res.id
            delete res.isDeleted
            setForm(res);
            setModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        setFormLoading(true);
        API({
            url: model?.mode == 'edit' ? URL.SPEAKER + '/' + model?.editId : URL.SPEAKER,
            method: model?.mode == 'edit' ? 'PATCH' : 'POST',
            data: form
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });

    }

    const SequenceColumn = ({ row }) => {
        const [seq, setSeq] = useState(row?.sequence ?? 0)
        const handleSequenceChange = (e) => {
            setSeq(e.target.value)
            API({
                url: URL.SPEAKER + '/' + row.id,
                method: 'PATCH',
                data: { sequence: e.target.value }
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                getData();
            });
        }
        return (
            <div className='col-12'>
                <div className="form-group mt-3 mb-3">
                    <input type="number" className="form-control text-center" value={seq} placeholder="Enter sequence" onChange={handleSequenceChange} />
                </div>
            </div>
        );
    }

    const StatusColumn = ({ row }) => {
        const handleStatusChange = (e) => {
            API({
                url: URL.SPEAKER + '/' + row.id,
                method: 'PATCH',
                data: { isActive: e.target.checked ? 'Active' : 'InActive' }
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                getData();
            });
        }
        return (
            <div className="form-group">
                <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" checked={row.isActive == 'Active' ? true : false} id={row.id} onChange={handleStatusChange} />
                    <label className="custom-control-label" htmlFor={row.id}></label>
                </div>
            </div>
        );
    }

    const ActionColumn = ({ row }) => {
        const removeItem = () => {
            confirmAlert({
                title: 'Are you sure?',
                message: 'Want to delete item.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => API({
                            url: URL.SPEAKER + '/' + row?.id,
                            method: 'DELETE',
                        }).then(res => {
                            if (res?.code) {
                                toast.error(res.message)
                                return;
                            }
                            toast.success('Speaker deleted succesfully');
                            getData();
                        })
                    },
                    { label: 'No', }
                ]
            });

        }
        return (
            <>
                <button className='btn btn-info btn-sm' onClick={() => handleEdit(row)}>< i className='fa fa-edit' /></button>
                <button className='ml-1 btn btn-danger btn-sm' onClick={removeItem}>< i className='fa fa-trash' /></button>
            </>
        );
    }

    const setFormData = (value, name) => {
        let data = _.cloneDeep(form);
        if (name == 'designation' || name == 'description' || name == "session") {
            data[name][language] = value;
        } else {
            data[name] = value;
        }
        setForm(data);
    }

    const resetForm = () => {
        setModel(null);
        setForm(fd);
        setLanguage('en');
        getData();
    }

    const onChangeFile = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        setFormLoading(true);
        let payload = { file: file };
        API({
            url: URL.UPLOAD,
            method: 'POST',
            data: payload,
            formData: true
        }).then(res => {
            e.target.value = null;
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            setFormData('uploads/' + res[0].filename, 'image');
        });
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Speaker</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Speaker</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Speaker Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <button className='btn btn-primary' onClick={handleCreate}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{model?.mode == 'create' ? 'Create' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <Language lang={language} onChange={lang => setLanguage(lang)} />
                    <div className='row'>
                        <div className='col-6'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="form-group mt-3 mb-3 ">
                                        <input type="text" className="form-control" value={form.name} placeholder="Enter Name" onChange={e => setFormData(e.target.value, "name")} />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group mt-3 mb-3 ">
                                        <input type="text" className="form-control" value={form.designation[language]} placeholder="Enter Designation" onChange={e => setFormData(e.target.value, 'designation')} />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group mt-3 mb-3 ">
                                        <input type="text" className="form-control" value={form.session[language]} placeholder="Enter Session" onChange={e => setFormData(e.target.value, 'session')} />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group mt-3 mb-3 ">
                                        <input type="datetime-local" className="form-control" value={form.dateTime} placeholder="Select date time" onChange={e => setFormData(e.target.value, 'dateTime')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                {
                                    form.image ? <div>
                                        <i style={{
                                            position: 'absolute',
                                            top: 10,
                                            left: 5,
                                            padding: 10,
                                            cursor: 'pointer',
                                        }} className="fa fa-times" onClick={() => setFormData('', 'image')} />
                                        <img src={DOMAIN_URL + form.image} width="100" className="img-responsive" />
                                    </div> : <input type="file" accept='image/*' onChange={e => onChangeFile(e)} style={{ marginTop: 3 }} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <textarea value={form.description[language]} rows="5" cols="50" name="description" placeholder='Enter description' className="form-control" onChange={e => setFormData(e.target.value, 'description')} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default Speaker;
