import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import _ from 'lodash';

import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import { toast } from 'react-toastify';


const Notification = () => {

    const [sendType, setSendType] = useState('all');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [url, setUrl] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = (page = 1) => {
        API({
            url: URL.USERS_ALL,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setUsers([]);
            const u = [];
            res.map(e => {
                u.push({
                    id: e.id,
                    name: e.firstName + ' ' + e.surname
                })
            })
            setUsers(u);
        });
    }

    const handleSend = () => {
        if (sendType == 'selected' && !selectedUsers.length) {
            toast.error('Please select users!');
            return;
        }
        if (!title) {
            toast.error('Please enter title!');
            return;
        }
        if (!message) {
            toast.error('Please enter title!');
            return;
        }
        const payload = {
            sendType,
            title,
            url,
            message,
            users: selectedUsers.map(e => { return e.id })
        }
        API({
            url: URL.NOTIFICATION_SEND,
            method: 'POST',
            data: payload
        }).then(res => {
            if (res?.code) {
                return;
            }
            setSendType('all');
            setTitle('');
            setMessage('');
            setUrl('');
            setSelectedUsers([]);
            toast.success('Notification sent successfully');
        });

    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Notification</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Notification</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className="col-2">
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input className="form-check-input radio-btn" type="radio" onChange={e => setSendType('all')} name="type" checked={sendType == 'all'} />
                                                    <label className="form-check-label">All Users</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input className="form-check-input radio-btn" type="radio" onChange={e => setSendType('selected')} name="type" checked={sendType == 'selected'} />
                                                    <label className="form-check-label">Selected Users</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        sendType == 'selected' ? (
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className="form-group mt-3 mb-3 ">
                                                        <Multiselect
                                                            placeholder="Select Users"
                                                            options={users}
                                                            onSelect={(list) => setSelectedUsers(list)}
                                                            onRemove={(list) => setSelectedUsers(list)}
                                                            displayValue="name"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="form-group mt-3 mb-3 ">
                                                <input type="email" className="form-control" value={title} placeholder="Enter Title" onChange={e => setTitle(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="form-group mt-3 mb-3 ">
                                                <textarea value={message} rows="5" cols="50" name="description" placeholder='Enter Message' className="form-control" onChange={(e) => setMessage(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className="form-group mt-3 mb-3 ">
                                                <input type="url" className="form-control" value={url} placeholder="Enter url (optional)" onChange={e => setUrl(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <button className='btn btn-primary' onClick={handleSend}>
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Notification;
