import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

import BGImage11 from '../assets/fe/images/menubg.png';

import { RoutesPath } from "../Routes";


const Schedule = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    return (
        <>
            <section className="page-title" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{t('agenda')}</h1>
                    </div>
                </div>
            </section>
            <center>
                <section className="register-section" style={{ overflowY: 'auto' }}>
                    <div className="auto-container">
                        <div className="col-12">
                            <table className="table table-bordered table-responsive text-center schedule-table" style={{ display: 'table' }}>

                                <thead>
                                    <tr className="schedule-table-bg-yellow">
                                        <th rowSpan="2"> {t('a01')}  </th>
                                        <th rowSpan="2"> {t('a02')} </th>
                                        <th colSpan="2"> {t('a03')} </th>
                                        <th rowSpan="2">{t('a04')} </th>
                                        <th rowSpan="2">{t('a05')} </th>
                                    </tr>
                                    <tr className="schedule-table-bg-yellow">
                                        <th> {t('a06')} </th>
                                        <th>{t('a07')} </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="schedule-table-bg-light-blue ">
                                        <td >
                                            {t('a08')}  <br /> {t('a09')}  <br /> {t('a10')}
                                        </td>
                                        <td colSpan="5">
                                            {t('a11')} <br />
                                            {t('a12')} <br />
                                            {t('a13')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="23" className="schedule-table-bg-grey ">
                                            {t('a14')}
                                            <br />
                                            <br />
                                            <br />
                                            {t('a15')}
                                            <br />
                                            {t('a16')}
                                        </td>
                                        <td colSpan="5" className="schedule-table-bg-green">
                                            {t('a17')}
                                            {t('a18')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td rowSpan="10" >
                                            {t('a19')} <br />
                                            {t('a20')} <br />
                                            {t('a21')}
                                        </td>
                                        <td>{t('d01')}</td>
                                        <td>{t('d02')}</td>
                                        <td colSpan={2}>{t('d03')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a22')}</td>
                                        <td>{t('a23')}</td>
                                        <td>{t('a24')}</td>
                                        <td rowSpan="9">{t('a25')} <br />
                                            {t('a26')}  <br />
                                            {t('a27')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a28')}</td>
                                        <td>{t('a29')}</td>
                                        <td>{t('a30')} </td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a31')} </td>
                                        <td>{t('a32')}</td>
                                        <td>
                                            {t('a33')}<br />
                                            {t('a34')}<br />
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a35')}</td>
                                        <td>{t('a36')}</td>
                                        <td>{t('a37')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a38')}</td>
                                        <td>{t('a39')}</td>
                                        <td>{t('a40')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a41')}</td>
                                        <td>{t('a42')}</td>
                                        <td>
                                            {t('a43')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a44')}</td>
                                        <td>{t('a45')}</td>
                                        <td>{t('a46')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a47')}</td>
                                        <td>{t('a48')}</td>
                                        <td>{t('a49')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td>{t('a50')}</td>
                                        <td>{t('a51')}</td>
                                        <td>{t('a52')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5">{t('a56')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-yellow">
                                        <td rowSpan="4">
                                            {t('a57')}<br /><br />
                                            {t('a58')} <br /><br />
                                            <b>{t('a59')}</b> <br /><br />
                                            {t('a60')}
                                        </td>
                                        <td>{t('a61')}</td>
                                        <td>{t('a62')}</td>
                                        <td>
                                            {t('a63')}<br />
                                            {t('a64')} <br />
                                            {t('a69')}<br />
                                            {t('a66')}
                                        </td>
                                        <td rowSpan="4">
                                            {t('a67')} <br />
                                            {t('a68')} <br />
                                            {t('a69')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-yellow">
                                        <td>{t('a70')}</td>
                                        <td>{t('a71')}</td>
                                        <td>
                                            {t('a72')} <br />
                                            {t('a73')}<br />
                                            {t('a74')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-yellow">
                                        <td>{t('a75')}</td>
                                        <td>{t('a76')}</td>
                                        <td>
                                            {t('a77')}<br />
                                            {t('a78')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-yellow">
                                        <td>{t('a79')}</td>
                                        <td>{t('a80')}</td>
                                        <td>
                                            {t('a81')} <br />
                                            {t('a82')}<br />
                                            {t('a83')}<br />
                                            {t('a84')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" colSpan="5">{t('a85')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-pink">
                                        <td rowSpan="4">
                                            {t('a86')} <br /><br />
                                            {t('a87')}<br /><br />
                                            <b>{t('a88')}</b> <br /><br />
                                            {t('a89')}
                                        </td>
                                        <td>{t('a90')}</td>
                                        <td>{t('a91')}</td>
                                        <td>
                                            {t('a92')}<br />
                                            {/* {t('a93')} < br /> */}
                                            {t('a94')} < br />
                                            {t('a95')}<br />
                                            {t('a96')}
                                        </td>
                                        <td rowSpan="4">
                                            {t('a97')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-pink">
                                        <td>{t('a98')}</td>
                                        <td>{t('a99')}</td>
                                        <td>
                                            {t('b01')} <br />
                                            {t('b02')} <br />
                                            {t('b03')}<br />
                                            {t('b04')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-pink">
                                        <td>{t('b05')}</td>
                                        <td>{t('b06')}</td>
                                        <td>
                                            {t('b07')}<br />
                                            {t('b08')} <br /> {t('b09')} <br />
                                            {t('b10')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-pink">
                                        <td>{t('b11')}</td>
                                        <td>{t('b12')}</td>
                                        <td>
                                            {t('b13')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" className="text-center">{t('b14')}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colSpan="2">{t('b15')}</td>
                                        <td colSpan="2">{t('b16')}</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="10" className="schedule-table-bg-yellow ">
                                            {t('b17')}<br />
                                            {t('b18')}<br />
                                            {t('b19')}
                                        </td>
                                        <td rowSpan='5' className="schedule-table-bg-green">
                                            {t('b20')}<br /><br />
                                            {t('b21')}<br /><br />
                                            <b>{t('b22')}</b><br /><br />
                                            {t('b23')}
                                        </td>
                                        <td className="schedule-table-bg-green">{t('b24')}</td>
                                        <td className="schedule-table-bg-green">{t('b25')}</td>
                                        <td className="schedule-table-bg-green">
                                            {t('b26')}<br />
                                            {t('b27')}  <br />
                                            {t('b28')}
                                            {t('b29')}
                                        </td>
                                        <td rowSpan='5' className="schedule-table-bg-green">
                                            {t('b30')}<br />
                                            {t('b31')} <br />
                                            {t('b32')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-green">
                                        <td>{t('b33')}</td>
                                        <td>{t('b34')}</td>
                                        <td>
                                            {t('b35')}<br />
                                            {t('b36')}<br />
                                            {t('b37')}<br />
                                            {t('b38')}<br />
                                            {t('b39')}<br />
                                            {t('b40')}<br />
                                            {t('b41')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-green">
                                        <td>{t('b42')}</td>
                                        <td>{t('b43')}</td>
                                        <td>
                                            {t('b44')}<br />
                                            {t('b45')}<br />
                                            {t('b46')} <br />
                                            {t('b460')} <br />
                                            {t('b47')}<br />
                                            {t('b470')}<br />
                                            {t('b48')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-green">
                                        <td>{t('b49')}</td>
                                        <td>{t('b50')}</td>
                                        <td>
                                            {t('b51')}<br />
                                            {t('b52')}<br />
                                            {t('b53')} <br />
                                            {t('b54')}<br />
                                            {t('b55')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-green">
                                        <td>{t('b56')}</td>
                                        <td>{t('b57')}</td>
                                        <td>
                                            {t('b58')}<br />
                                            {t('b59')}<br />
                                            {t('b60')} <br />
                                            {t('b61')} <br />
                                            {t('b62')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='5'>
                                            {t('b63')} <br />
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-light-blue ">
                                        <td rowSpan='2'>
                                            {t('b64')}<br /><br />
                                            {t('b65')}<br /><br />
                                            <b>{t('b66')}</b><br /><br />
                                            {t('b67')}
                                        </td>
                                        <td>{t('b68')}</td>
                                        <td>{t('b69')}</td>
                                        <td>
                                            {t('b70')}<br />
                                            {t('b71')}<br />
                                            {t('b72')}<br />
                                            {t('b73')}

                                        </td>
                                        <td rowSpan='2'>
                                            {t('b74')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-light-blue ">
                                        <td>{t('b75')}</td>
                                        <td>{t('b76')}</td>
                                        <td>
                                            {t('b77')}<br />
                                            {t('b78')}<br />
                                            {t('b79')}<br />
                                            {t('b80')}<br />
                                            {t('b81')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='5'>
                                            {t('b82')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-orange">
                                        <td >
                                            {t('b83')} <br />
                                            {t('b84')}
                                        </td>
                                        <td colSpan='4'>
                                            <p className="text-justify">
                                                {t('b85')}<br />
                                                {t('b86')}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr >
                                        <td rowSpan="9" className="schedule-table-bg-blue2">
                                            {t('b87')} <br /> {t('b88')} <br /> {t('b89')}
                                        </td>
                                        <td rowSpan='7' className="schedule-table-bg-blue">
                                            {t('b90')}<br /><br />
                                            <b>{t('b91')}</b><br /><br />
                                            {t('b92')}
                                        </td>
                                        <td className="schedule-table-bg-blue">{t('b93')}</td>
                                        <td className="schedule-table-bg-blue">{t('b94')}</td>
                                        <td className="schedule-table-bg-blue text-left" >
                                            {t('b95')} <br />
                                            {t('b96')}<br />
                                            {t('b97')}<br />
                                            {t('b98')}<br />
                                            {t('b99')} <br />
                                            {t('c01')}
                                        </td>
                                        <td className="schedule-table-bg-blue" rowSpan='7'>{t('c02')} <br />
                                            {t('c03')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-blue">
                                        <td>{t('c04')}</td>
                                        <td>{t('c05')}</td>
                                        <td>{t('c06')}

                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-blue">
                                        <td>{t('c07')}</td>
                                        <td>{t('c08')}</td>
                                        <td>{t('c09')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-blue">
                                        <td>{t('c10')}</td>
                                        <td>{t('c11')}</td>
                                        <td>{t('c12')} <br />
                                            {t('c13')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-blue">
                                        <td>{t('c14')}</td>
                                        <td>{t('c15')}</td>
                                        <td>{t('c16')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-blue">
                                        <td>{t('c17')}</td>
                                        <td>{t('c18')}</td>
                                        <td>{t('c19')}
                                        </td>
                                    </tr>
                                    <tr className="schedule-table-bg-blue">
                                        <td>{t('c20')}</td>
                                        <td>{t('c21')}</td>
                                        <td>{t('c22')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='5'>{t('c23')}</td>
                                    </tr>
                                    <tr className="schedule-table-bg-light-blue">
                                        <td>
                                            {t('c24')} <br />
                                            {t('c25')}
                                        </td>
                                        <td colSpan='4'>
                                            <p className="text-justify">{t('c26')}<br />
                                                {t('c27')}<br /></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </center>
        </>
    );
}
export default Schedule;
