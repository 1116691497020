import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { logout } from "../reducers/authReducer";

import { RoutesPath } from "../Routes";

const AdminLayout = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes('backoffice')) {
            import('../assets/plugins/fontawesome-free/css/all.min.css');
            import('../assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css');
            import('../assets/css/adminlte.min.css');
            import('../assets/css/custom.css');
        }
    }, []);

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#">
                                <i className="far fa-bell"></i>
                                <span className="badge badge-warning navbar-badge">15</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-header">15 Notifications</span>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item">
                                    <i className="fas fa-envelope mr-2"></i> 4 new messages
                                    <span className="float-right text-muted text-sm">3 mins</span>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item">
                                    <i className="fas fa-users mr-2"></i> 8 friend requests
                                    <span className="float-right text-muted text-sm">12 hours</span>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item">
                                    <i className="fas fa-file mr-2"></i> 3 new reports
                                    <span className="float-right text-muted text-sm">2 days</span>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                                <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link" data-widget="fullscreen" onClick={() => dispatch(logout())} role="button">
                                <i className="fas fa-sign-out-alt"></i>
                            </span>
                        </li>
                    </ul>
                </nav>

                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <a className="brand-link text-center">
                        <b><span className="brand-text font-weight-bold">RPF UNI</span></b>
                    </a>
                    <div className="sidebar">
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item">
                                    <Link to={RoutesPath.Dashboard.path} className={`nav-link ${location.pathname == RoutesPath.Dashboard.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Dashboard
                                            {/* <span className="right badge badge-danger">New</span> */}
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.Country.path} className={`nav-link ${location.pathname == RoutesPath.Country.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Country
                                        </p>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to={RoutesPath.Domain.path} className={`nav-link ${location.pathname == RoutesPath.Domain.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Domain
                                        </p>
                                    </Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to={RoutesPath.Agency.path} className={`nav-link ${location.pathname == RoutesPath.Agency.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Agency
                                        </p>
                                    </Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to={RoutesPath.Designation.path} className={`nav-link ${location.pathname == RoutesPath.Designation.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Designation
                                        </p>
                                    </Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to={RoutesPath.Department.path} className={`nav-link ${location.pathname == RoutesPath.Department.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Department
                                        </p>
                                    </Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <Link to={RoutesPath.Addon.path} className={`nav-link ${location.pathname == RoutesPath.Addon.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Requirements
                                        </p>
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to={RoutesPath.Language.path} className={`nav-link ${location.pathname == RoutesPath.Language.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Language
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.CMSPages.path} className={`nav-link ${location.pathname == RoutesPath.CMSPages.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            CMS Pages
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.Speaker.path} className={`nav-link ${location.pathname == RoutesPath.Speaker.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Speaker
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.Users.path} className={`nav-link ${location.pathname == RoutesPath.Users.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Users
                                        </p>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to={RoutesPath.Supports.path} className={`nav-link ${location.pathname == RoutesPath.Supports.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Supports
                                        </p>
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to={RoutesPath.Notification.path} className={`nav-link ${location.pathname == RoutesPath.Notification.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Notification
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.FeedbackFrom.path} className={`nav-link ${location.pathname == RoutesPath.FeedbackFrom.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Feedback Form
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.Glimpses.path} className={`nav-link ${location.pathname == RoutesPath.Glimpses.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Glimpses
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.Download.path} className={`nav-link ${location.pathname == RoutesPath.Download.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Downloads
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.FeedbackReport.path} className={`nav-link ${location.pathname == RoutesPath.FeedbackReport.path || location.pathname == RoutesPath.FeedbackReportDetails.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Feedback Report
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.EventSummaryReport.path} className={`nav-link ${location.pathname == RoutesPath.EventSummaryReport.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Event Summary Report
                                        </p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={RoutesPath.Setting.path} className={`nav-link ${location.pathname == RoutesPath.Setting.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Settings
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </aside>

                <div className="content-wrapper">
                    <Outlet />
                </div>

                <footer className="main-footer d-print-none">
                    <strong>Copyright &copy; 2022 <a href="https://compughost.com">CCS</a>.</strong> All rights
                    reserved.
                </footer>
            </div>
        </div>
    );
}
export default AdminLayout;