import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import BGImage11 from '../assets/fe/images/menubg.png';
import { logout, setUser } from "../reducers/authReducer";

import { BASE_URL, DOMAIN_URL, modelBoxStyle } from '../Helpers/Constants';
import { URL } from "../Helpers/URL";
import { API } from "../Helpers/ApiService";
import { Box, Modal } from "@mui/material";
import ReactPlayer from "react-player";
import moment from "moment";

const Account = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [downloads, setDownloads] = useState([]);
    const [mediaModel, setMediaModel] = useState(null);
    const [activeTab, setActiveTab] = useState('download');
    const [feedbackForms, setFeedbackForms] = useState([]);
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        API({
            url: URL.USER_DOWNLOADS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setDownloads(res)
        });

        const tabCheck = searchParams.get('tab');
        if (tabCheck == 'feedback') {
            setActiveTab('feedback')
        }
    }, []);

    useEffect(() => {
        if (auth?.value?.extra?.notifications?.length) {
            setNotifications(auth?.value?.extra?.notifications);
        }
        if (auth?.value?.extra?.feedbacks) {
            setFeedbackForms(auth?.value?.extra?.feedbacks);
        }
    }, [])

    const downloadMedia = (url) => {
        window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}media${url.replace('uploads', '')}`, "_blank");
    }

    return (
        <>
            <section className="page-title mb-5" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{t('my_account')}</h1>
                    </div>
                </div>
            </section>
            <section className=" pt-1 pb-3 mb-5 mt-5 ">
                <div className="auto-container pt-5" style={{ minHeight: '50vh' }}>
                    <div className='row ' >
                        <div className='col-12 mb-3'>
                            <ul className="nav my-account">
                                <li
                                    className={activeTab == 'download' ? 'nav-item active' : 'nav-item'}
                                    onClick={() => setActiveTab('download')}
                                >
                                    <a className="nav-link"><b>Downloads</b></a>
                                </li>
                                <li className={activeTab == 'certificate' ? 'nav-item active' : 'nav-item'} onClick={() => setActiveTab('certificate')}>
                                    <a className="nav-link"><b>Certificate</b></a>
                                </li>
                                <li className={activeTab == 'feedback' ? 'nav-item active' : 'nav-item'} onClick={() => setActiveTab('feedback')}>
                                    <a className="nav-link"><b>Feedback</b></a>
                                </li>
                                <li className={activeTab == 'notifications' ? 'nav-item active' : 'nav-item'} onClick={() => setActiveTab('notifications')}>
                                    <a className="nav-link"><b>Notifications</b></a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" onClick={() => dispatch(logout())}>{t('logout')}</a>
                                </li> */}
                            </ul>
                        </div>
                        {
                            activeTab == 'download' ? <div className='col-12' style={{ border: '1px solid #000', borderRadius: 10, }}>
                                {
                                    downloads?.length ? <>
                                        <div className="row">
                                            {
                                                downloads.map((e, i) => <div className="col-4 p-3" key={i} onClick={() => setMediaModel(e)}>
                                                    <video width="100%" controls={false}>
                                                        <source src={DOMAIN_URL + e.file} type="video/mp4" />
                                                    </video>
                                                    <h3 className="text-center">{e.title[auth.language] ? e.title[auth.language] : e.title['en']}</h3>
                                                </div>)
                                            }
                                        </div>
                                    </> : <p className="text-center">No downloads available!</p>
                                }
                            </div> : null
                        }
                        {
                            activeTab == 'certificate' ? <div className='col-12 text-center' style={{ border: '1px solid #000', borderRadius: 10, }}>
                                {
                                    auth?.value?.certificate ? <img className="p-5" src={DOMAIN_URL + auth?.value?.certificate} /> : <p>Certificate not uploaded!</p>
                                }
                                <br />
                                {
                                    auth?.value?.certificate ? <span className="btn btn-primary mb-3" onClick={() => downloadMedia(auth?.value?.certificate)} >Download</span> : null
                                }
                            </div> : null
                        }
                        {
                            activeTab == 'feedback' ? <div className='col-12 text-center' style={{ border: '1px solid #000', borderRadius: 10, }}>
                                {
                                    feedbackForms.length ? <>
                                        {
                                            feedbackForms.map((e, i) => <div className="col-12" key={i}>
                                                {
                                                    e.filled ? <span style={{
                                                        border: '1px solid #c9c9c9',
                                                        margin: 10,
                                                        padding: 10,
                                                        borderRadius: 10,
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <p>{moment(e.date).format('DD-MM-YYYY HH:mm a')}</p>
                                                        <p>{e.speakerObj?.name}</p>
                                                        <p>{e.topic[auth.language]}</p>
                                                        <p>
                                                            <i className="fa fa-check" style={{
                                                                borderRadius: 100,
                                                                background: 'green',
                                                                color: '#fff',
                                                                padding: 5
                                                            }}></i>
                                                        </p>
                                                    </span> : <Link style={{
                                                        border: '1px solid #c9c9c9',
                                                        margin: 10,
                                                        padding: 10,
                                                        borderRadius: 10,
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}
                                                        to={'/feedback/' + e.id}
                                                    >
                                                        <p>{moment(e.date).format('DD-MM-YYYY HH:mm a')}</p>
                                                        <p>{e?.speakerObj?.name}</p>
                                                        <p>{e.topic[auth.language]}</p>
                                                        <p>
                                                            <i className="fa fa-arrow-right"></i>
                                                        </p>
                                                    </Link>
                                                }

                                            </div>)
                                        }
                                    </> : <p>Feedback form not available!</p>
                                }
                            </div> : null
                        }
                        {
                            activeTab == 'notifications' ? <div className='col-12' style={{ border: '1px solid #000', borderRadius: 10, }}>
                                <div className="row">
                                    {
                                        notifications?.map((e, i) => <div className="col-12" key={i}>
                                            <div style={{
                                                border: '1px solid #c9c9c9',
                                                margin: 10,
                                                padding: 10,
                                                borderRadius: 10
                                            }}>
                                                <h4>{e.title}</h4>
                                                <p>{e.body}</p>
                                                {
                                                    e.url ? <a href={e.url} target="_blank"> {e.url}</a> : null
                                                }
                                            </div>
                                        </div>)
                                    }
                                </div>
                            </div> : null
                        }
                    </div>
                </div>
            </section>
            <Modal
                open={mediaModel ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[modelBoxStyle, { minWidth: '50%' }]}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{mediaModel?.title[auth.language] ? mediaModel?.title[auth.language] : mediaModel?.title['en']}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => setMediaModel(null)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 mt-3">
                            <video width="100%" controls autoPlay>
                                <source src={DOMAIN_URL + mediaModel?.file} type="video/mp4" />
                            </video>

                        </div>
                        {/* <div className="col-12 text-right">
                            <span className="theme-btn btn-style-one text-right" style={{ cursor: 'pointer' }} onClick={() => downloadMedia(mediaModel.file)}>
                                <span className="btn-title color-white" >
                                    Download
                                </span >
                            </span>
                        </div> */}
                        <div className="col-12 mt-3">
                            <p>{mediaModel?.description[auth.language] ? mediaModel?.description[auth.language] : mediaModel?.description['en']}</p>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
export default Account;
